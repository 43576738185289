@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Montserrat, sans-serif;
    }
}

.arrow {
    width: 0;
    height: 25rem;
    border-top: 12.5rem solid transparent;
    border-bottom: 12.5rem solid transparent;
    border-left: 25rem solid #fec40e;
}

.arrow2 {
    width: 0;
    height: 25rem;
    border-top: 12.5rem solid transparent;
    border-bottom: 12.5rem solid transparent;
    border-right: 25rem solid #fec40e;
}

/* #myLinks {
    display: block;
} */




@media (max-width: 1079px) {
    .ComingSoonPage {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: full;
      position: absolute;
      .logo {
        height: 6rem;
      }
      .words {
        padding-bottom: 7rem;
        font-size: 2.75rem;
      }
      .crane{
        height: auto;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .cone {
        display: none;
    }
    }
    .NotFountPage {
        flex-direction: column;
        width: full;
        justify-content: center;
        align-items: center;

        .logo {
            height: 3rem;
        }
        .car1 {
            left: 1rem;
            position: fixed;
        }
        .car2 {
            right: 1rem;
            position: fixed;
        }
    }
}

@media (min-width:1080px) {
    .crane {
        position: absolute;
    }
  }
